import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormatDateToText } from "utils/formatter";
import { getNewsList } from "../../store/action/newsAction";
import "../News/News.scss";

const News = (props) => {
  const large = useMedia("(min-width: 960px)");
  useEffect(() => {
    props.newslist();
  }, []);

  function useMedia(query) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    // Activity normally for componentDidMount + componentDidUpdate
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addListener(listener);

      return () => media.removeListener(listener);
    }, [query]);

    // publish value for render
    return matches;
  }

  if (props.loading) {
    return <Loading />;
  } else {
    return (props.nqSuperSelected && props.isSuperPortfolioExist) || !props.nqSuperSelected ? (
      <div className="News">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} key="announcement">
            <div class="announcement-container">
              <label class="title">Important Update for Our Members</label>
              <p>
                We are transitioning clients from NQ Super & Pension to Freedom of Choice, a division of Acclaim Wealth
                (formerly AMG Super). This change will result in lower fees for members.
              </p>
              <p>
                Starting February 1st, members will be redirected to the new Acclaim Online portal for account access.
                For product and fund information, please visit{" "}
                <a href="https://www.acclaimwealth.com.au" target="_blank" rel="noopener noreferrer">
                  www.acclaimwealth.com.au
                </a>
                .
              </p>
              <p>
                For any inquiries, please continue to contact{" "}
                <a href="mailto:super@ddhgraham.com.au">super@ddhgraham.com.au</a>.
              </p>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    ) : null;
  }
};
const mapStateToProps = (state) => {
  return {
    newsDate: state.newsReducer.newsDate,
    loading: state.newsReducer.loading,
    nqSuperSelected: state.sideBarReducer.nqSuperSelected,
    isSuperPortfolioExist: state.portfoliosReducer.isSuperPortfolioExist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { newslist: () => dispatch(getNewsList()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
